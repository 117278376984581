<template>
	<RightDrawer title="下沉一线" :visible.sync="visible">
		<template slot="content">
			<div class='content_p'>
				<p >姓名：{{details.username}}</p>
				<p>部门：{{details.depart}}</p>
				<p>岗位：{{details.role}}</p>
			</div>
			<div v-for='(item,index) in details.list' :key='index'>
					<div class='content_p' >
					<p>打卡时间：{{item.start_time}}</p>
					<p>打卡地址：{{item.start_address}}</p>
					<p>现场照片：{{item.image_num}}张</p>	
					</div>
					<div>
						<LookImages :list="item.image" :is_img="true"></LookImages>
					</div>
					
					</div>
		</template>
	</RightDrawer>
</template>

<script>
	import {
		getDetail
	} from "@/api/supervision";
	import RightDrawer from "@/components/RightDrawer";
	import LookImages from "@/components/LookImages";
	export default {
		components: {
			RightDrawer,
			LookImages
		},
		props: {

		},
		data() {
			return {
				list: [{}, {}, {}],
				imgs: [{}],
				visible: false,
				details: {
					list: [],
				},
			}
		},
		methods: {
			get_details(item, index) {
				getDetail({
					data: {
						uid: item.uid,
						visit_date: item.visit_date
					}
				}).then(res => {
					this.details = res.data.list;
					this.visible = true;
				})
			},
		}

	}
</script>
<style lang="less">
	
	.content_p{
		p{
			margin:5px 0;
		}
		margin-bottom:20px;
	}
</style>